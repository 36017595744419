import '../styles/aurora-tv.css';

import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';

import Img from 'gatsby-image';
import InfoIconPopup from '../components/aurora/infoPopUp';
import InfoPop from '../components/aurora/infoPop';
import Layout from '../components/layout';
import SEO from '../components/seo';
import helpActiveIcon from '../images/aurora-tv/help-active.svg';
import helpIcon from '../images/aurora-tv/help.svg';
import logoGreen from '../images/aurora-tv/aurora-green.svg';
import meta from '../constants/meta-descriptions';
import netsurfIcon from '../images/aurora-tv/netsurf.svg';
import parentControlActiveIcon from '../images/aurora-tv/parent-control-active.svg';
import parentControlIcon from '../images/aurora-tv/parent-control.svg';
import radioSignalActiveIcon from '../images/aurora-tv/radio-signal-active.svg';
import radioSignalIcon from '../images/aurora-tv/radio-signal.svg';
import recordingActiveIcon from '../images/aurora-tv/recording-active.svg';
import recordingIcon from '../images/aurora-tv/recording.svg';
import searchActiveIcon from '../images/aurora-tv/search-active.svg';
import searchIcon from '../images/aurora-tv/search.svg';
import timeShiftActiveIcon from '../images/aurora-tv/time-shift-active.svg';
import timeShiftIcon from '../images/aurora-tv/time-shift.svg';
import tvLibraryActiveIcon from '../images/aurora-tv/tv-library-active.svg';
import tvLibraryIcon from '../images/aurora-tv/tv-library.svg';
import videosActiveIcon from '../images/aurora-tv/videos-active.svg';
import videosIcon from '../images/aurora-tv/videos.svg';

const FEATURES_TOOLTIP_TEXT = {
	search:
		'Търсачката на приложението е лесна и удобна. Можете да търсите като използвате основното меню или да ползвате дистанционното, като изпишете в реално време Вашият критерий. Тази функция Ви дава възможности и за умно търсене, за да достигате бързо и лесно до желаното ТВ съдържание.',
	timeShift:
		'Една от най - практичните функции на TV AURORA е TimeShift. Тя позволява да връщате ТВ съдържание до 120 часа назад и свободата да гледате телевизия спрямо Вашите предпочитания и Вашата програма',
	recording:
		'Запис на филм или предаване и възпроизвеждането му е възможно и лесно с NetSurf TV AURORA. Вашите записи са на разположение и се съхраняват, докато Вие не прецените да ги премахнете. Функцията е високо оценена от абонатите на NetSurf и горещо препоръчвана от спортните фенове.',
	videos:
		'Над 2000 филмови заглавия на Ваше разположение! Функцията предлага удобно търсене по различни критерии и ново съдържание всеки ден. Игрални, анимационни филми и сериали са достъпни за абонатите на NetSurf с TV AURORA.',
	tvLibrary:
		'Удобен и функционален справочник на всички канали в реално време. Информация за минали и предстоящи предавания, възможност за подредба по категории и отбелязване на предпочитания. Краткото резюме за всяко предаване Ви информира и улеснява избора Ви.',
	parentControl:
		'Създайте защитена телевизионна среда за Вашето дете. Функцията Ви позволява да ограничите, чрез код неподходящо за детето Ви ТВ съдържание.  Също така директно може да скриете всички канали 18+.',
	radio:
		'С NetSurf TV AURORA любимата Ви радиостанция е на един клик, дори тя да не се излъчва във Вашето населено място.  Заредете се с  настроение.',
	help:
		'За Ваше улеснение, разработихме <a style="text-decoration: underline"	 href="/help-center/tv/obuchitelni-clipove-tv-aurora/">видеоуроци</a> за всяка от функциите на NetSurf TV AURORA. Всяко видео  нагледно обяснява възможностите на TV AURORA (TВ приложение или ТВ приемник).  Ако все пак имате въпроси, не се колебайте да се свържете с нашия съпорт център на тел.: 0885250000'
};

const FEATURES = [
	{
		id: 0,
		type: 'search',
		icon: searchIcon,
		iconActive: searchActiveIcon,
		label: 'Търсене',
		text: FEATURES_TOOLTIP_TEXT.search
	},
	{
		id: 1,
		type: 'timeShift',
		icon: timeShiftIcon,
		iconActive: timeShiftActiveIcon,
		label: 'TimeShift',
		text: FEATURES_TOOLTIP_TEXT.timeShift
	},
	{
		id: 2,
		type: 'recording',
		icon: recordingIcon,
		iconActive: recordingActiveIcon,
		label: 'Запис на предавания',
		text: FEATURES_TOOLTIP_TEXT.recording
	},
	{
		id: 3,
		type: 'videos',
		icon: videosIcon,
		iconActive: videosActiveIcon,
		label: 'ТВ Архив',
		text: FEATURES_TOOLTIP_TEXT.videos
	},
	{
		id: 4,
		icon: tvLibraryIcon,
		type: 'tvLibrary',
		iconActive: tvLibraryActiveIcon,
		label: 'Програмен справочник',
		text: FEATURES_TOOLTIP_TEXT.tvLibrary
	},
	{
		id: 5,
		type: 'parentControl',
		icon: parentControlIcon,
		iconActive: parentControlActiveIcon,
		label: 'Родителски контрол',
		text: FEATURES_TOOLTIP_TEXT.parentControl
	},
	{
		id: 6,
		type: 'radio',
		icon: radioSignalIcon,
		iconActive: radioSignalActiveIcon,
		label: 'Радио',
		text: FEATURES_TOOLTIP_TEXT.radio
	},
	{
		id: 7,
		type: 'help',
		icon: helpIcon,
		iconActive: helpActiveIcon,
		label: 'Помощ',
		text: FEATURES_TOOLTIP_TEXT.help
	}
];

const AuroraTv = () => {
	const [activeFeature, setActiveFeature] = useState(undefined);

	const data = useStaticQuery(graphql`
		{
			heroImg: file(relativePath: { eq: "aurora-tv/aurora-hero.png" }) {
				childImageSharp {
					fluid(maxHeight: 850) {
						...GatsbyImageSharpFluid
					}
				}
			}
			footerImg: file(relativePath: { eq: "aurora-tv/footer-hero.png" }) {
				childImageSharp {
					fluid(maxHeight: 350) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<>
			<Layout activeTab={'/aurora-tv/'} hideFooter>
				<SEO title={meta.promo.title} description={meta.promo.metaDescription} />
				<div className="aurora-container" id="aurora-hero-wrap">
					<Img fluid={data.heroImg.childImageSharp.fluid} objectFit="contain" />
				</div>
				<div className="bg-black text-light aurora-container mt-4">
					<div className="content-container my-8-aurora px-4">
						<div className="mb-2-aurora">
							<b>NetSurf TV AURORA</b> предоставя телевизионно изживяване от
							ново поколение.
						</div>
						<div className="mb-2-aurora">
							Напълно безплатно, лесно и бързо можете да инсталирате
							приложението на Вашия смарт телевизор. Интерактивната
							телевизия от NetSurf е достъпна за SMART телевизори, чрез TV
							app и за обикновени телевизори, чрез ТВ приемници (STB).
						</div>
						<div>
							Възползвайте се от интерактивна и безкабелна телевизия от
							NetSurf.
						</div>
					</div>
					<div className="my-8-aurora">
						<div className="content-container text-center mb-2-aurora">
							<img src={logoGreen} />
							<div className="features-title ">Функции</div>
						</div>
						<div className="d-none d-lg-block">
							<div
								className="d-flex flex-wrap m-auto flex-center"
								style={{ maxWidth: 900 }}
							>
								{FEATURES.map((feature) => (
									<>
										{activeFeature === feature.id ? (
											<div
												className="aurora-card-active"
												onClick={() =>
													setActiveFeature(undefined)
												}
											>
												<img src={feature.iconActive} />
												<div className="icon-position">
													<InfoIconPopup
														popupText={feature.text}
													/>
												</div>
												<div className="text-white">
													{feature.label}
												</div>
											</div>
										) : (
											<div
												className="aurora-card"
												onClick={() =>
													setActiveFeature(feature.id)
												}
											>
												<img src={feature.icon} />
												<div className="icon-position">
													<InfoIconPopup
														popupText={feature.text}
													/>
												</div>
												<div className="text-grey-aurora">
													{feature.label}
												</div>
											</div>
										)}
									</>
								))}
							</div>
							<div
								className="d-none d-md-block text-white m-auto text-left"
								style={{ maxWidth: 900, height: 100, paddingLeft: 40 }}
							>
								{activeFeature !== undefined && (
									<div
										dangerouslySetInnerHTML={{
											__html:
												FEATURES_TOOLTIP_TEXT[
													FEATURES[activeFeature].type
												]
										}}
									></div>
								)}
							</div>
						</div>
						<div className="d-lg-none">
							<div
								className="d-flex flex-wrap m-auto flex-center"
								style={{ maxWidth: 900 }}
							>
								{FEATURES.map((feature) => (
									<InfoPop popupText={feature.text}>
										<div
											className="aurora-card-active"
											onClick={() => setActiveFeature(undefined)}
										>
											<img src={feature.iconActive} />
											<div className="icon-position"></div>
											<div className="text-white">
												{feature.label}
											</div>
										</div>
									</InfoPop>
								))}
							</div>
						</div>
					</div>
					<div className="d-none d-md-block blur-container-archive">
						<div className="box blur">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									Над <b>2000+ заглавия</b> в AURORA TV Архив
								</div>
							</div>
						</div>
					</div>
					<div className="d-md-none blur-container-archive-mobile">
						<div className="box shadow-mobile">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									Над <b>2000+ заглавия</b> в AURORA TV Архив
								</div>
							</div>
						</div>
					</div>
					<div className="d-none d-md-block blur-container-time-shift">
						<div className="box">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									Връщаш предавания до <b>5 дни назад</b>
								</div>
							</div>
						</div>
					</div>
					<div className="d-md-none blur-container-time-shift-mobile">
						<div className="box shadow-mobile">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									Връщаш предавания до <b>5 дни назад</b>
								</div>
							</div>
						</div>
					</div>
					<div className="d-none d-md-block blur-container-new-gen">
						<div className="box shadow">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									<div>Търсачка от</div>
									<div>
										<b>ново поколение</b>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-md-none blur-container-new-gen-mobile">
						<div className="box shadow-mobile">
							<div className="d-flex flex-column tv-archive-title">
								<div className="mb-2-aurora">
									<div>Търсачка от</div>
									<div>
										<b>ново поколение</b>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="content-container text-center my-4-aurora">
							<img src={logoGreen} />
						</div>
					</div>
					<div className="content-container my-4-aurora">
						<div className="text-center">
							<Link
								to="/help-center/tv/obuchitelni-clipove-tv-aurora/"
								className="btn btn-primary green my-1-aurora box-shadow-b"
							>
								Видеоуроци
							</Link>
						</div>
						<Img
							className="filter-blur"
							fluid={data.footerImg.childImageSharp.fluid}
							objectFit="fit"
						/>
						<div className="text-center">
							<div className="my-1-aurora">
								<img src={netsurfIcon} />
							</div>
							<div className="my-1-aurora">
								<img src={logoGreen} />
							</div>
							<Link
								to="/za-doma/"
								className="btn btn-primary green my-1-aurora box-shadow-b"
							>
								Виж пакетите
							</Link>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export default AuroraTv;

