import '../shared/info-icon-popup/info-icon-popup.css';

import React, { useRef, useState } from 'react';

import Popup from '../shared/popup';
import PropTypes from 'prop-types';
import infoActiveIcon from '../../images/aurora-tv/info-active.svg';
import infoIcon from '../../images/aurora-tv/info.svg';

const InfoIconPopup = ({ popupText }) => {
	const [showInfo, setShowInfo] = useState({
		visible: false,
		ref: null,
		current: null
	});

	const infoRef = useRef(null);

	return (
		<>
			<img
				src={showInfo.visible ? infoActiveIcon : infoIcon}
				onMouseEnter={() =>
					setShowInfo({
						visible: true,
						ref: infoRef,
						content: popupText
					})
				}
				onMouseLeave={(e) => setShowInfo({ visible: false })}
				ref={infoRef}
			/>
			<Popup
				showTelephonePopover={showInfo.visible}
				target={showInfo.ref || {}}
				placement="top-end"
			>
				<div onMouseLeave={(_) => setShowInfo({ visible: false })}>
					<div
						className="p-4 bg-white info-icon-popup col-12 col-md-3"
						style={{ maxWidth: '350px' }}
					>
						<span>{showInfo.content}</span>
					</div>
				</div>
			</Popup>
		</>
	);
};

InfoIconPopup.propTypes = {
	popupText: PropTypes.string.isRequired,
	className: PropTypes.string,
	small: PropTypes.bool
};

export default InfoIconPopup;

